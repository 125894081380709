import axios from "axios";
import endpoints from "../utils/endpoints";
import { accessToken } from "../utils/token";
import fileDownload from "js-file-download";

const getAllEvent = async (params: any) => {
  const url = endpoints.EVENT;

  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params: params,
  });

  return response;
};

const viewEventDetail = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.VIEW_EVENT(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};

const createEvent = async (params: any) => {
  const url = endpoints.CREATE_EVENT;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const deleteEvent = async (id: number) => {
  const url = endpoints.DELETE_EVENT(id);

  const { data: response } = await axios.delete(url, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const updateEvent = async (params: any) => {
  const url = endpoints.VIEW_EVENT(params?.data.eventId);
  const { data: response } = await axios.patch(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const downloadRedeemLogs = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.REDEEM_LOG(id);
    await axios
      .get(url, {
        headers: {
          Authorization: accessToken,
          ContentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileDownload(blob, "redeem_logs.xlsx");
      });
  }
};

const getRedeemLogs = async (params: any) => {
  const url = endpoints.REDEEM_LOGS;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const downloadCheckinCheckoutLogs = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.CHECKIN_LOG(id);
    await axios
      .get(url, {
        headers: {
          Authorization: accessToken,
          ContentType:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fileDownload(blob, "checkin_checkout_logs.xlsx");
      });
  }
};

const getCheckinCheckoutLogs = async (params: any) => {
  const url = endpoints.CHECKIN_LOGS;
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const getStatusCheckout = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.CHECKOUT_STATUS(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};

const changeCheckoutStatus = async (id: any) => {
  if (parseInt(id)) {
    const url = endpoints.CHANGE_CHECKOUT_STATUS(id);
    const { data: response } = await axios.get(url, {
      headers: {
        Authorization: accessToken,
      },
    });
    return response;
  }
};
const downloadFile = async (path: any) => {
  const url = endpoints.DOWNLOAD_EXCEL;
  await axios.post(url, { path }, {
    headers: {
      Authorization: accessToken,
      ContentType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "blob",
  }).then((res) => {
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fileDownload(blob, "tickets.xlsx");
  });
};

const getReport = async (params: any) => {
  const url = endpoints.EVENT + "/report";
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params,
  });
  return response;
};

const uploadVolunteer = async (params: any) => {
  const url = endpoints.EVENT + "/upload";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const getVolunteer = async (params: any) => {
  const url = endpoints.EVENT + "/volunteer";
  const { data: response } = await axios.get(url, {
    headers: {
      Authorization: accessToken,
    },
    params,
  });
  return response;
};

const uploadBulkRedeem = async (params: any) => {
  const url = endpoints.EVENT + "/bulk-redeem";
  const { data: response } = await axios.post(url, params, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const service = {
  getAllEvent,
  viewEventDetail,
  createEvent,
  deleteEvent,
  updateEvent,
  getRedeemLogs,
  uploadVolunteer,
  downloadRedeemLogs,
  getCheckinCheckoutLogs,
  downloadCheckinCheckoutLogs,
  getStatusCheckout,
  changeCheckoutStatus,
  downloadFile,
  getReport,
  getVolunteer,
  uploadBulkRedeem,
};

export default service;