import { Box, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import MainLayout from "../components/MainLayout";
import ProtectedPermissionPage from "../components/ProtectedPermissionPage";
import service from "../services/ticket";

const TicketDetail = (): JSX.Element => {
  const params = useParams();
  const [data, setData] = useState({
    id: '',
    email: '',
    is_redeemed: false,
    name: '',
    order_id: '',
    phone: '',
    ticket_id: '',
    seat_number: '',
    category: '',
    last_updated_time: '',
  });
  if (params.id !== data.id) {
    service.viewTicketDetail(params.id).then((response) => {
      setData({
        ...response.data,
        id: params.id
      });
    }).catch((err) => {
      console.log(err);
    });
  }
  return (
    <MainLayout>
      <Helmet>
        <title>Ticket</title>
      </Helmet>

      <Breadcrumb
        breadcrumbs={[{ label: "Ticket", href: "/app/ticket" }]}
        label={`${data.ticket_id}`}
        href="/app/ticket"
      />

      <ProtectedPermissionPage acceptPermissions={[1, 2]}>
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={{ lg: 4, md: 2 }}>
            <Grid item lg={4} md={12}>
              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Name
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.name}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Email
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.email}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Phone Number
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.phone}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Category
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.category}
                </Typography>
              </Stack>
            </Grid>
            <Grid item lg={4} md={12}>
              <Stack>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Order Id
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.order_id}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Seat Number
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.seat_number !== "null" ? data.seat_number : "-"}
                </Typography>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Stack>
                  <Typography variant="body1" color="grey.600" fontWeight="300">
                    Redeemed?
                  </Typography>
                  {data.is_redeemed
                    ? <Typography
                      variant="body1"
                      color="green"
                      fontWeight="700"
                      sx={{ mt: 0.3 }}
                    >
                      YES
                    </Typography>
                    : <Typography
                      variant="body1"
                      color="red"
                      fontWeight="700"
                      sx={{ mt: 0.3 }}
                    >
                      NO
                    </Typography>
                  }
                </Stack>
              </Stack>

              <Stack sx={{ mt: 2 }}>
                <Typography variant="body1" color="grey.600" fontWeight="300">
                  Redeem Time
                </Typography>
                <Typography
                  variant="body1"
                  color="grey.600"
                  fontWeight="700"
                  sx={{ mt: 0.3 }}
                >
                  {data.last_updated_time !== "" ? new Intl.DateTimeFormat('id-ID',
                    { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })
                    .format(new Date(data.last_updated_time)) : "-"}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </ProtectedPermissionPage>
    </MainLayout>
  );
};

export default TicketDetail;