import { get } from "lodash";
import { Navigate, useRoutes } from "react-router-dom";
import { useAppSelector } from "../hooks/useSelector";
import DashboardLayout from "../layouts/Dashboard";
import MinimalLayout from "../layouts/Minimal";
import AccountProfilePage from "../pages/AccountProfilePage";
import CategoryDetail from "../pages/CategoryDetail";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import CheckIn from "../pages/CheckIn";
import CheckinCheckoutLogs from "../pages/CheckinCheckoutLogs";
import CheckOut from "../pages/CheckOut";
import CheckVolunteer from "../pages/CheckVolunteer";
import Coupon from "../pages/Coupon";
import Event from "../pages/Event";
import EventDetail from "../pages/EventDetail";
import EventCreate from "../pages/EventEditPage";
import ForgotPage from "../pages/ForgotPage";
import LoadingPage from "../pages/LoadingPage";
import PageNotFound from "../pages/PageNotFound";
import PdfViewer from "../pages/PDFViewer";
import Redeem from "../pages/Redeem";
import RedeemLogs from "../pages/RedeemLogs";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import SignInPage from "../pages/SignInPage";
import Ticket from "../pages/Ticket";
import TicketDetail from "../pages/TicketDetail";
import UserCreate from "../pages/UserCreate";
import UserEdit from "../pages/UserEdit";
import UserList from "../pages/UserList";

export default function Routes() {
  const { user } = useAppSelector((state) => state.global);
  const chooseDefaultRoute: any = () => {
    const role = get(user, 'role_id')
    if (user?.is_admin || role === 1) {
      return { element: <Navigate to="/app/event" />, index: true }
    }

    if (role === 2) {
      return { element: <Navigate to="/app/redeem" />, index: true }
    }

    if (role === 3) {
      return { element: <Navigate to="/app/checkin" />, index: true }
    }

    if (role === 4) {
      return { element: <Navigate to="/app/checkout" />, index: true }
    }

    if (role === 5) {
      return { element: <Navigate to="/app/checkin" />, index: true }
    }

    return { element: <LoadingPage />, index: true }
  }
  return useRoutes([
    {
      path: "/app",
      element: <DashboardLayout />,
      children: [
        {
          path: '/app',
          children: [
            chooseDefaultRoute(),
          ]
        },
        {
          path: '/app',
          children: [
            { element: <Navigate to="/app/event" />, index: true }
          ]
        },
        {
          path: "/app/profile",
          children: [
            { element: <AccountProfilePage />, index: true },
            {
              element: <ChangePasswordPage />,
              path: "/app/profile/change-password",
            },
          ],
        },
        {
          path: "/app/event",
          children: [
            { element: <Event />, index: true },
            {
              element: <EventDetail />,
              path: "/app/event/:id",
            },
            {
              element: <EventCreate />,
              path: "/app/event/edit/:id",
            },
            {
              element: <EventCreate />,
              path: "/app/event/create-event",
            },
            {
              element: <CategoryDetail />,
              path: "/app/event/category-detail/:id",
            },
            {
              element: <RedeemLogs />,
              path: "/app/event/redeem-logs/:id",
            },
            {
              element: <CheckinCheckoutLogs />,
              path: "/app/event/checkin-checkout-logs/:id",
            },
          ],
        },
        {
          path: "/app/ticket",
          children: [
            { element: <Ticket />, index: true },
            {
              element: <TicketDetail />,
              path: "/app/ticket/:id",
            }
          ],
        },
        {
          path: "/app/redeem",
          children: [{ element: <Redeem />, index: true }],
        },
        {
          path: "/app/checkin",
          children: [
            { element: <CheckIn />, index: true },
          ],
        },
        {
          path: "/app/checkout",
          children: [
            { element: <CheckOut />, index: true },
          ],
        },
        {
          path: "/app/users",
          children: [
            { element: <UserList />, index: true },
            {
              element: <UserCreate />,
              path: "/app/users/create-user",
            },
            {
              element: <UserEdit />,
              path: "/app/users/edit/:id",
            }
          ],
        },
        {
          path: "/app/users",
          element: <Navigate to="/app/users/manage" />,
        },
        {
          path: "/app/coupon",
          children: [
            { element: <Coupon />, index: true },
          ],
        },
        {
          path: "*",
          element: <PageNotFound fullWidth={false} />,
        },
      ],
    },
    {
      path: "/",
      element: <MinimalLayout />,
      children: [
        {
          path: "/",
          element: <SignInPage />,
        },
        {
          path: "/forgot-password",
          element: <ResetPasswordPage />,
        },
        {
          path: "/reset-password",
          element: <ForgotPage />,
        },
        {
          path: "/pdf-invitation/:id",
          element: <PdfViewer />,
        },
        {
          path: "/check-volunteer/:id",
          element: <CheckVolunteer />,
        },
        {
          path: "*",
          element: <PageNotFound fullWidth />,
        },
      ],
    },
  ]);
}
